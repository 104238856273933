import { addPropertyControls, ControlType, RenderTarget } from "framer"
import React, { useState, useRef, useEffect } from "react"
import {
    motion,
    useSpring,
    useMotionTemplate,
    transform,
    useMotionValue,
    useTransform,
} from "framer-motion"

const spring = { stiffness: 400, damping: 20 }
const inputRange = [0, 1]

/**
 * Based on https://twitter.com/benjaminnathan/status/1288092942968791041
 * Benjamin den Boer + Adam Seckel
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ZapIcon({ scale, borderRadius, colorA, colorB }) {
    const size = 92
    const clientX = useMotionValue(0.5)
    const clientY = useMotionValue(0.5)
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    useEffect(() => {
        if (isCanvas) return
        const convertCursorPosition = (e) => {
            // This can get out of sync when resizing the window
            clientX.set(e.clientX / window.innerWidth)
            clientY.set(e.clientY / window.innerHeight)
        }

        window.addEventListener("mousemove", convertCursorPosition)

        return () => {
            window.removeEventListener("mousemove", convertCursorPosition)
        }
    }, [])

    const rotateX = useTransform(clientX, inputRange, [-60, 60])
    const rotateY = useTransform(clientY, inputRange, [60, -60])
    const rotateXSpring = useSpring(rotateX, spring)
    const rotateYSpring = useSpring(rotateY, spring)

    const x = useTransform(clientX, inputRange, [-20, 20])
    const y = useTransform(clientY, inputRange, [-20, 20])
    const xSpring = useSpring(x, spring)
    const ySpring = useSpring(y, spring)

    const shadowX = useTransform(clientX, inputRange, [8, -8])
    const shadowY = useTransform(clientY, inputRange, [24, 12])
    const shadowXSpring = useSpring(shadowX, spring)
    const shadowYSpring = useSpring(shadowY, spring)

    const filter = useMotionTemplate`drop-shadow(${shadowXSpring}px ${shadowYSpring}px 10px rgba(0, 0, 68, 0.3))`

    return (
        <motion.div
            style={{
                width: size,
                height: size,
                rotateX: rotateXSpring,
                rotateY: rotateYSpring,
                display: "flex",
                placeItems: "center",
                placeContent: "center",
                overflow: "visible",
                margin: "auto",
                transformPerspective: 1200,
            }}
            initial={{
                borderRadius,
                background: `linear-gradient(180deg, ${colorA} 0%, ${colorB} 100%)`,
            }}
            animate={{
                borderRadius,
                background: `linear-gradient(180deg, ${colorA} 0%, ${colorB} 100%)`,
            }}
        >
            <motion.div
                style={{ width: 64, height: 64 }}
                initial={{ scale }}
                animate={{ scale }}
                transition={{ type: "spring", ...spring }}
            >
                <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    style={{
                        x: xSpring,
                        y: ySpring,
                        filter,
                    }}
                    transition={{ type: "spring", ...spring }}
                >
                    <path
                        d="M 28.285 6.692 C 29.329 4.952 32 5.692 32 7.721 L 32 20.417 C 32 21.383 32.784 22.167 33.75 22.167 L 53.468 22.167 C 55.022 22.167 55.983 23.863 55.183 25.196 L 35.715 57.642 C 34.671 59.381 32 58.641 32 56.613 L 32 43.917 C 32 42.95 31.216 42.167 30.25 42.167 L 10.533 42.167 C 8.978 42.167 8.018 40.471 8.818 39.138 Z"
                        fill="rgb(255,255,255)"
                    ></path>
                </motion.svg>
            </motion.div>
        </motion.div>
    )
}

addPropertyControls(ZapIcon, {
    scale: {
        title: "Scale",
        type: ControlType.Number,
        defaultValue: 1,
        displayStepper: true,
        step: 0.1,
    },
    borderRadius: {
        title: "Radius",
        type: ControlType.Number,
        defaultValue: 24,
        displayStepper: true,
        step: 1,
    },
    colorA: {
        title: "Color A",
        type: ControlType.Color,
        defaultValue: "#0CF",
    },
    colorB: {
        title: "Color B",
        type: ControlType.Color,
        defaultValue: "#86F",
    },
})
